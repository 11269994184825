import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { Block, Body } from '@thesisedu/web/dist'
import styled from 'styled-components'
import { KnowledgeBaseSearch } from '@thesisedu/gatsby-knowledge-base'
import { SupportTemplateQuery } from '../graphqlTypes'
import { Container, LargeH1, Subtitle } from '../components/styled'
import { renderAst } from '../components/rehype'
import { SEO } from '../components/SEO'
import { MDPageContent } from '../components/MDPageContent'
import { SupportWidget } from '../components/support/SupportWidget'

export interface PageTemplateProps {
  data: SupportTemplateQuery
}
const SupportTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  return (
    <Container>
      <SEO title={data.markdownRemark.frontmatter.title} article />
      <div>
        <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
          <Block marginBottom={'@size-xs'}>
            <Subtitle>
              <Link to={'/learn/articles/'}>Knowledge Base</Link> &nbsp;/
            </Subtitle>
          </Block>
          <LargeH1 isBlock={false}>{data.markdownRemark.frontmatter.title}</LargeH1>
        </Block>
        <SupportContent>
          {renderAst(data.markdownRemark.htmlAst)}
          <Block marginTop={'@size-section-sm'}>
            <Body isBlock style={{ textAlign: 'right' }}>
              <a href={'mailto:support@prodigiesacademy.com'}>
                Contact support about this article.
              </a>
            </Body>
            <KnowledgeBaseSearch />
          </Block>
        </SupportContent>
        <Block marginTop={'@size-section-md'}>
          <SupportWidget />
        </Block>
      </div>
    </Container>
  )
}

export const SupportContent = styled(MDPageContent)`
  max-width: 800px;
`

export default SupportTemplate

export const query = graphql`
  query supportTemplate($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerptAst
      tableOfContents
      frontmatter {
        title
      }
    }
  }
`
