import * as React from 'react'
import { Block } from '@thesisedu/web/dist'
import { Changelog } from '@thesisedu/gatsby-knowledge-base'
import { Container, LargeH1, Subtitle } from '../../components/styled'
import { SEO } from '../../components/SEO'
import { SupportWidget } from '../../components/support/SupportWidget'
import { SupportContent } from '../../templates/support'

const ChangelogContent: React.FC = () => {
  return (
    <Container>
      <SEO title={'Changelog'} />
      <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
        <LargeH1 isBlock={false}>Changelog</LargeH1>
        <Block marginTop={'@size-xs'}>
          <Subtitle>What's new with Prodigies Academy?</Subtitle>
        </Block>
      </Block>
      <SupportContent>
        <Changelog />
      </SupportContent>
      <Block marginTop={'@size-section-md'}>
        <SupportWidget />
      </Block>
    </Container>
  )
}

const ChangelogPage: React.FC = () => <ChangelogContent />

export default ChangelogPage
